import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "@context/GlobalContext";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import {
  CryptoPair,
  ExchangeRates,
  GlobalContextKind,
} from "@common/type/type";
import { useCurrencyRates } from "app/hooks/use-currency-rates";
import { trimZeros } from "@utils/trimZeros";
import { formatNumber } from "@utils/formatNum";
import useMediaQuery from "app/hooks/useMediaQuery";
import dynamic from "next/dynamic";
import SwapButton from "./SwapButton";
import Typography from "../Typography";
import CurrencyInput from "./CurrencyInput";
import styles from "./CurrencyCalculator.module.scss";
import { convertCurrency } from "@components/ui/ExchangeTable";

const DynamicModal = dynamic(() => import("@components/ui/Modal"));
const DynamicCurrencyForm = dynamic(() => import("./CurrencyForm"));

type Props = {
  forceDescription?: true;
};

const REGEX_BRACKETS_MATCHER = /\[.*?\]/g;

export default function CurrencyCalculator({ forceDescription }: Props) {
  const { state, dispatch } = useContext(GlobalContext);
  const router = useRouter();
  const { amount, currency_pairs, currentCity } = state;
  const { data, isSuccess } = useCurrencyRates(state, {
    keepPreviousData: true,
  });
  const fromRate = data?.items[0];
  const initialFromValue = (fromRate?.fromAmount || 1).toString();
  const initialToValue = trimZeros(String(fromRate?.toAmount || 0));
  const [currentFromValue, setCurrentFromValue] = useState(initialFromValue);
  const [currentToValue, setCurrentToValue] = useState(initialToValue);
  const [isOpen, setIsOpen] = useState(false);
  const [pairIndex, setPairIndex] = useState<0 | 1>(0);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation("home");

  const [leftCurrency, rightCurrency] = currency_pairs;
  const isFullPair = leftCurrency.id && rightCurrency.id;
  const hasSideDescription = isFullPair && fromRate && isSuccess;
  const canShowDescription = !isMobile || forceDescription;

  useEffect(() => {
    const reinitCalcState = () => {
      setCurrentToValue(initialToValue);
    };
    reinitCalcState();
  }, [initialFromValue, initialToValue]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      if (currentFromValue) {
        dispatch({
          type: GlobalContextKind.AMOUNT,
          payload: Number(currentFromValue),
        });
      }
    }, 350);
    return () => clearTimeout(delayInputTimeoutId);
  }, [currentFromValue, 350]);

  const handleFromInput = (nextFromValue: string) => {
    if (Number(nextFromValue) === Number(currentFromValue)) {
      setCurrentFromValue(nextFromValue);
      return;
    }

    const scale = +nextFromValue / +initialFromValue;

    setCurrentToValue(trimZeros(+initialToValue * scale));
    setCurrentFromValue(nextFromValue);
  };
  const handleToInput = (nextToValue: string) => {
    if (Number(nextToValue) === Number(currentToValue)) {
      setCurrentToValue(nextToValue);
      return;
    }

    const scale = +nextToValue / +initialToValue;

    setCurrentToValue(nextToValue);
    setCurrentFromValue(trimZeros(+initialFromValue * scale));
  };

  const handleOpen = (pairIndex: 0 | 1) => {
    setIsOpen(true);
    setPairIndex(pairIndex);
  };
  const handleClose = () => setIsOpen(false);

  const handleSwap = () => {
    if (!isFullPair) return;

    const reversedPair: CryptoPair = [currency_pairs[1], currency_pairs[0]];

    const from = reversedPair[0].abbreviation;
    const to = reversedPair[1].abbreviation;
    const ratePath = `${from.toLowerCase()}-to-${to.toLowerCase()}`;
    const newRoute = router.pathname.replace(REGEX_BRACKETS_MATCHER, ratePath);

    router.push(newRoute);
    dispatch({
      type: GlobalContextKind.PAIRS,
      payload: reversedPair,
    });
  };

  const rightAmount = useMemo(() => {
    return convertCurrency(
      {
        fromAmount: fromRate?.fromAmount || 0,
        toAmount: fromRate?.toAmount || 0,
      },
      amount,
      true
    ).toValue;
  }, [fromRate, amount]);

  return (
    <div className={styles.container}>
      <div className={styles.side_container}>
        <label className={styles.side_title} htmlFor="currency-give">
          {t("title-give")}
        </label>
        <CurrencyInput
          id="currency-give"
          target={currency_pairs[0]}
          value={currentFromValue}
          onSelect={() => handleOpen(0)}
          onInput={handleFromInput}
          isLoading={!currency_pairs[0].id}
        />
        <div
          className={classNames(
            styles.side_description_wrapper,
            !canShowDescription && styles.hidden
          )}
        >
          {hasSideDescription && (
            <Typography as="span" addClass={styles.side_description}>
              {`${t("min-recieved")}: `}
              <span className={styles.danger}>
                {`${
                  formatNumber(data.items[0]?.minamount, 6, {
                    minValue: 0.001,
                  }) || 0
                } ${currency_pairs[0].abbreviation.toUpperCase()}`}
              </span>
            </Typography>
          )}
        </div>
      </div>
      <div className={styles.side_container}>
        <label className={styles.side_title} htmlFor="currency-get">
          {t("title-get")}
        </label>
        <CurrencyInput
          id="currency-get"
          target={currency_pairs[1]}
          value={data?.items.length ? currentToValue : null}
          onSelect={() => handleOpen(1)}
          onInput={handleToInput}
          isLoading={!currency_pairs[0].id}
        />
        {hasSideDescription && canShowDescription && (
          <Typography as="span" addClass={styles.side_description}>
            {`${t("course")}: `}
            {`${formatNumber(amount, 3, {
              minValue: 0.001,
            })} ${leftCurrency.abbreviation.toUpperCase()} = ${formatNumber(
              rightAmount,
              3,
              { minValue: 0.001 }
            )} ${rightCurrency.abbreviation.toUpperCase()}`}
          </Typography>
        )}
      </div>
      <div className={styles.swap_wrapper}>
        <SwapButton onClick={handleSwap} />
      </div>
      {isOpen && (
        <DynamicModal onClose={handleClose}>
          <DynamicCurrencyForm onSelect={handleClose} activeIndex={pairIndex} />
        </DynamicModal>
      )}
    </div>
  );
}
